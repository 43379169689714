import { createTheme } from "@mui/material/styles";
import "@fontsource/playfair-display";
import "@fontsource/playfair-display/700.css";
import "@fontsource/playfair-display/900.css";
import "@fontsource/raleway";
import "@fontsource/raleway/200.css";
import "@fontsource/herr-von-muellerhoff";
import "@fontsource/modern-antiqua";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#163a34",
    },
    secondary: {
      main: "#fbf1ec",
      darker: "#f2d4cd",
      transparent: "#f2d4cdc2",
    },
    text: {
      main: "#666666",
      dark: "#163a34",
    },
    invite: {
      green: "#aeb8a2",
      darkGray: "#1f2633",
      medGray: "#1f2633aa",
    },
  },
  typography: {
    title: {
      fontFamily: "Playfair Display",
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1,
      textTransform: "lowercase",
    },
    smalltitle: {
      fontFamily: "Playfair Display",
      fontSize: "2.5rem",
      fontWeight: 500,
      lineHeight: 1,
      textTransform: "lowercase",
    },
    subtitle: {
      fontFamily: "Raleway",
      fontSize: "0.9rem",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    header: {
      fontFamily: "Raleway",
      fontSize: "1.25rem",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    bodyheavy: {
      fontFamily: "Raleway",
      fontSize: "1.5rem",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    bodyregular: {
      fontFamily: "Raleway",
      fontSize: "1.25rem",
      fontWeight: 300,
    },
    navlink: {
      fontFamily: "Playfair Display",
      textTransform: "lowercase",
    },
    note: {
      fontFamily: "Raleway",
      fontSize: "1rem",
      color: "#666666",
      lineHeight: 1,
    },
    error: {
      fontFamily: "Raleway",
      fontSize: "1rem",
      color: "red",
    },
    inviteHeader: {
      fontFamily: "Raleway",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "0.8rem",
    },
    inviteSerifSmall: {
      fontFamily: "Playfair Display",
      textTransform: "lowercase",
      fontWeight: 400,
      fontSize: "1.5rem",
      letterSpacing: "2px",
      lineHeight: 1.2,
    },
  },
});

export default mainTheme;
