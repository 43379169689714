import React, { Suspense, lazy } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/Pages/Components/Loading";
import { AuthProvider } from "./utils/useAuth";

const Welcome = lazy(() => import("./components/Pages/Welcome"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const NotFound = lazy(() => import("./components/Errors/NotFound"));
const Form = lazy(() => import("./components/Form/Form"));
const Success = lazy(() => import("./components/Form/Success"));
const Wedding = lazy(() => import("./components/Pages/Wedding"));
const Travel = lazy(() => import("./components/Pages/Travel"));
const Registry = lazy(() => import("./components/Pages/Registry"));
const RSVP = lazy(() => import("./components/Pages/RSVP/RSVP"));
const Invite = lazy(() => import("./components/Pages/Invite/Invite"));
const ThankYou = lazy(() => import("./components/Pages/RSVP/ThankYou"));

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="" exact element={<Welcome />} />
              <Route path="wedding" element={<Wedding />} />
              <Route path="travel" element={<Travel />} />
              <Route exact path="rsvp" element={<RSVP />} />
              <Route exact path="rsvp/:lang/success" element={<ThankYou />} />
              <Route path="registry" element={<Registry />} />
              <Route path="contact-info" element={<Form />} />
              <Route path="success" element={<Success />} />
              <Route path="admin" element={<Admin />} />
              <Route path="invite" element={<Invite />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
