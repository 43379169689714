import React, {
  useState, useContext, createContext,
} from 'react';

import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';

import server from './axiosConfig';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  const login = (creds) => server
    .post('/users/local', creds)
    .then((res) => {
      setUser(res.data.user);
      return res.data.user;
    });

  const logout = (done) => server
    .post(
      '/users/logout',
    )
    .then(() => {
      setUser(null);
      done();
    });

  const checkUser = () => server
    .get(
      '/users/verify',
    )
    .then((res) => {
      if (res.data.isAuthenticated) {
        setUser(res.data.user);
      }
    });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user, login, logout, checkUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired,
};
